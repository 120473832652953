// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-curriculum-template-curriculum-template-tsx": () => import("../src/layouts/curriculum-template/CurriculumTemplate.tsx" /* webpackChunkName: "component---src-layouts-curriculum-template-curriculum-template-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-curriculum-tsx": () => import("../src/pages/curriculum.tsx" /* webpackChunkName: "component---src-pages-curriculum-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-team-tsx": () => import("../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

